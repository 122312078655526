@import (reference) '../../styles/mixins.less';

.layout {
  height: 100%;

  .sider{
  }
  .menu{
   padding-top: 20px;
  }

  .header{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .layout-Title{
      font-size: 20px;
      font-weight: 500;
    }
  }
}